import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import MySwiper from "../components/swiper/swiper"
import SampleForm from "../components/form/form"
import "react-lazy-load-image-component/src/effects/blur.css"
import ReactCardFlip from "react-card-flip"

//reusable component
import ServicesLinks from "../components/services/ServicesLinks"

import about from "../images/home/aboutus-image.png"
import flexible from "../images/home/benefits/Flexible Terms Icon.svg"
import flexiblehover from "../images/home/benefits/on-hover/Flexible Terms Icon-hover.svg"
import competitive from "../images/home/benefits/Competetive Rates Icon.svg"
import competitivehover from "../images/home/benefits/on-hover/Competetive Rates Icon-hover.svg"
import quicke from "../images/home/benefits/Quick Approval Process Icon.svg"
import quickehover from "../images/home/benefits/on-hover/Quick Approval Process Icon-hover.svg"
import easy from "../images/home/benefits/Easy Online Management Tools Icon.svg"
import easyhover from "../images/home/benefits/on-hover/Easy Online Management Tools Icon-hover.svg"

class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isFlipped: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClick2 = this.handleClick2.bind(this)
    this.handleClick3 = this.handleClick3.bind(this)
    this.handleClick4 = this.handleClick4.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
  }
  handleClick2(e) {
    e.preventDefault()
    this.setState(prevState => ({ isFlipped2: !prevState.isFlipped2 }))
  }
  handleClick3(e) {
    e.preventDefault()
    this.setState(prevState => ({ isFlipped3: !prevState.isFlipped3 }))
  }
  handleClick4(e) {
    e.preventDefault()
    this.setState(prevState => ({ isFlipped4: !prevState.isFlipped4 }))
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    // const stories = this.props.data.allSanitySuccessStories.nodes
    // const posts = this.props.data.allSanityPost.edges
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <Hero />
        <div id="index">
          <section id="benefits">
            <div className="container">
              <div className="row">
                <div className="col-12 mb-5">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      HOW YOU BENEFIT
                    </h6>
                    <hr className="section-hr mb-5" />
                    <h2 className="section-title">
                      Why choose to work with National Partners? Here are the
                      main benefits you can take full advantage of:
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="benefit-list">
                    <ReactCardFlip
                      isFlipped={this.state.isFlipped}
                      flipDirection="horizontal"
                      flipSpeedFrontToBack="1.0"
                      flipSpeedBackToFront="1.0"
                    >
                      <div className="front">
                        <button
                          onClick={this.handleClick}
                          className="benefit-flip"
                        >
                          <div className="benefit-item-front text-center">
                            <img
                              src={flexible}
                              className="benefit-icon mb-3 img-fluid"
                            />
                            <img
                              src={flexiblehover}
                              className="benefit-icon-hover mb-3 img-fluid"
                            />
                            <h5 className="benefit-title">
                              Flexible
                              <br />
                              Terms
                            </h5>
                          </div>
                        </button>
                      </div>

                      <button
                        onClick={this.handleClick}
                        className="benefit-flip"
                      >
                        <div className="benefit-item-back text-left">
                          <div className="d-flex justify-content-start align-items-center">
                            <h2 className="flip-benefit-number mr-2">1.</h2>
                            <h4 className="flip-benefit-title">
                              Flexible
                              <br />
                              Terms
                            </h4>
                          </div>
                          <hr className="my-1" />
                          <p className="fli-p">
                            have the flexibility that you deserve. Compensation
                            plans should fit your needs
                          </p>
                          <Link
                            to="/premium-finance#benefit"
                            className="flip-benefit-cta"
                          >
                            READ MORE...
                          </Link>
                        </div>
                      </button>
                    </ReactCardFlip>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="benefit-list">
                    <ReactCardFlip
                      isFlipped={this.state.isFlipped2}
                      flipDirection="horizontal"
                      flipSpeedFrontToBack="1.0"
                      flipSpeedBackToFront="1.0"
                    >
                      <div className="front">
                        <button
                          onClick={this.handleClick2}
                          className="benefit-flip"
                        >
                          <div className="benefit-item-front text-center">
                            <img
                              src={competitive}
                              className="benefit-icon mb-3 img-fluid"
                              alt="Competitive Rates"
                            />
                            <img
                              src={competitivehover}
                              className="benefit-icon-hover mb-3 img-fluid"
                              alt="Competitive Rates Hover"
                            />
                            <h5 className="benefit-title">
                              Competitive
                              <br />
                              Rates
                            </h5>
                          </div>
                        </button>
                      </div>

                      <button
                        onClick={this.handleClick2}
                        className="benefit-flip"
                      >
                        <div className="benefit-item-back text-left">
                          <div className="d-flex justify-content-start align-items-center">
                            <h2 className="flip-benefit-number mr-2">2.</h2>
                            <h4 className="flip-benefit-title">
                              Competitive
                              <br />
                              Rates
                            </h4>
                          </div>
                          <hr className="my-1" />
                          <p className="fli-p">
                            we strive to provide rates that give the best value
                            for your money
                          </p>
                          <Link
                            to="/premium-finance#benefit"
                            className="flip-benefit-cta"
                          >
                            READ MORE...
                          </Link>
                        </div>
                      </button>
                    </ReactCardFlip>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="benefit-list">
                    <ReactCardFlip
                      isFlipped={this.state.isFlipped3}
                      flipDirection="horizontal"
                      flipSpeedFrontToBack="1.0"
                      flipSpeedBackToFront="1.0"
                    >
                      <div className="front">
                        <button
                          onClick={this.handleClick3}
                          className="benefit-flip"
                        >
                          <div className="benefit-item-front text-center">
                            <img
                              src={quicke}
                              className="benefit-icon mb-3 img-fluid"
                              alt="Quicke"
                            />
                            <img
                              src={quickehover}
                              className="benefit-icon-hover mb-3 img-fluid"
                              alt="Quicke Hover"
                            />
                            <h5 className="benefit-title">
                              Quick Approval
                              <br />
                              Process
                            </h5>
                          </div>
                        </button>
                      </div>

                      <button
                        onClick={this.handleClick3}
                        className="benefit-flip"
                      >
                        <div className="benefit-item-back text-left">
                          <div className="d-flex justify-content-start align-items-center">
                            <h2 className="flip-benefit-number mr-2">3.</h2>
                            <h4 className="flip-benefit-title">
                              Quick Approval
                              <br />
                              Process
                            </h4>
                          </div>
                          <hr className="my-1" />
                          <p className="fli-p">
                            cut down on waiting time and minimize delays with
                            your
                          </p>
                          <Link
                            to="/premium-finance#benefit"
                            className="flip-benefit-cta"
                          >
                            READ MORE...
                          </Link>
                        </div>
                      </button>
                    </ReactCardFlip>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-4">
                  <div className="benefit-list">
                    <ReactCardFlip
                      isFlipped={this.state.isFlipped4}
                      flipDirection="horizontal"
                      flipSpeedFrontToBack="1.0"
                      flipSpeedBackToFront="1.0"
                    >
                      <div className="front">
                        <button
                          onClick={this.handleClick4}
                          className="benefit-flip"
                        >
                          <div className="benefit-item-front text-center">
                            <img
                              src={easy}
                              className="benefit-icon mb-3 img-fluid"
                              alt="Easy"
                            />
                            <img
                              src={easyhover}
                              className="benefit-icon-hover mb-3 img-fluid"
                              alt="Easy Hover"
                            />
                            <h5 className="benefit-title">
                              Easy Online
                              <br />
                              Management Tools
                            </h5>
                          </div>
                        </button>
                      </div>

                      <button
                        onClick={this.handleClick4}
                        className="benefit-flip"
                      >
                        <div className="benefit-item-back text-left">
                          <div className="d-flex justify-content-start align-items-center">
                            <h2 className="flip-benefit-number mr-2">4.</h2>
                            <h4 className="flip-benefit-title">
                              Easy Online
                              <br />
                              Management Tools
                            </h4>
                          </div>
                          <hr className="my-1" />
                          <p className="fli-p">
                            make use of high-tech online solutions to expand
                            your agency or
                          </p>
                          <Link
                            to="/premium-finance#benefit"
                            className="flip-benefit-cta"
                          >
                            READ MORE...
                          </Link>
                        </div>
                      </button>
                    </ReactCardFlip>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about-us">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-heading text-center mb-5">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      WHO WE ARE
                    </h6>
                    <hr className="section-hr mb-5" />
                    <h2 className="section-title">
                      National Partners - Premium Finance for Businesses and
                      Agents Made Affordable
                    </h2>
                  </div>
                </div>
                <div className="col-12 col-lg-10 col-md-9">
                  <div className="aboutus-img">
                    <img className="img-fluid" src={about} />
                  </div>
                </div>
                <div className="col-12 col-lg-2 col-md-3">
                  <div className="aboutus-body">
                    <p className="section-p">
                      At National Partners, taking care of agents and brokers,
                      as well as direct customers, are our top-most priority.
                      With a team of highly qualified and experienced finance
                      executives dedicated to providing the highest standards in
                      the premium finance industry, you can rest assured that
                      your needs will be met.
                    </p>
                    <Link className="section-cta text-gold" to="/about-us">
                      MORE ABOUT US
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="services" className="solutions-wrapper">
            <div className="container">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      OUR SERVICES
                    </h6>
                    <hr className="section-hr mb-5" />
                    <h2 className="section-title mb-4">
                      Premium Financing Solutions from
                      <br />
                      National Partners
                    </h2>
                    <p className="section-p">
                      National Partners focus on building long-term
                      relationships with our agent clients, so we can provide
                      the right solution that will meet your needs now and as
                      they evolve over time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <ServicesLinks />
              </div>
            </div>
          </section>
          {/* <section id="stories">
            <div className="container">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      SUCCESS STORIES
                    </h6>
                    <hr className="section-hr mb-5" />
                  </div>
                </div>
                <div className="col-12">
                  <MySwiper slidesperview={1}>
                    {stories.map(story => (
                      <div className="row stories-slide" key={story.id}>
                        <div className="story-item pt-5">
                          <svg
                            width="130"
                            height="123"
                            viewBox="0 0 130 123"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="story-quote"
                          >
                            <path
                              opacity="0.1"
                              d="M13.9148 123C9.30478 115.387 5.88709 107.774 3.66158 100.161C1.59503 92.3888 0.561768 84.6963 0.561768 77.0832C0.561768 62.8085 4.05893 49.1683 11.0534 36.1625C18.2068 22.9981 28.1421 10.9439 40.8593 0L53.974 8.08895C55.2457 8.88199 56.0404 9.75433 56.3584 10.706C56.8353 11.6576 57.0738 12.6093 57.0738 13.5609C57.0738 15.7814 56.2789 17.6847 54.6893 19.2708C52.1459 22.4429 49.6818 25.7737 47.2974 29.2631C44.9129 32.7524 42.7669 36.4797 40.8593 40.4449C38.9517 44.2514 37.4416 48.2959 36.3289 52.5783C35.2161 56.8607 34.6597 61.3811 34.6597 66.1393C34.6597 71.2147 35.3751 76.4487 36.8058 81.8414C38.3954 87.2341 41.0182 92.9439 44.6744 98.971C45.7872 100.716 46.3436 102.54 46.3436 104.443C46.3436 108.25 44.1976 110.946 39.9056 112.532L13.9148 123ZM86.4027 123C81.7927 115.387 78.3751 107.774 76.1495 100.161C74.083 92.3888 73.0497 84.6963 73.0497 77.0832C73.0497 62.8085 76.547 49.1683 83.5415 36.1625C90.6949 22.9981 100.63 10.9439 113.347 0L126.462 8.08895C127.734 8.88199 128.529 9.75433 128.846 10.706C129.323 11.6576 129.562 12.6093 129.562 13.5609C129.562 15.7814 128.767 17.6847 127.177 19.2708C124.634 22.4429 122.17 25.7737 119.785 29.2631C117.401 32.7524 115.255 36.4797 113.347 40.4449C111.44 44.2514 109.93 48.2959 108.817 52.5783C107.704 56.8607 107.148 61.3811 107.148 66.1393C107.148 71.2147 107.863 76.4487 109.294 81.8414C110.883 87.2341 113.506 92.9439 117.163 98.971C118.275 100.716 118.832 102.54 118.832 104.443C118.832 108.25 116.686 110.946 112.394 112.532L86.4027 123Z"
                              fill="#2A3342"
                            />
                          </svg>

                          <div className="story-content text-center">
                            <h4 className="story-author mb-4">{story.name}</h4>
                            <p className="text-center f-400">{story.excerpt}</p>
                            <Link
                              className="story-cta"
                              to={`/stories/${story.slug.current}`}
                            >
                              READ MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </MySwiper>
                </div>
              </div>
            </div>
          </section> */}
        </div>
        {/* <div className="blog-section bg-light">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">blog</h6>
              <h2>Latest Posts</h2>
            </div>
            <div className="row justify-content-around py-5">
              {posts.map(post => (
                <div className="col-md-6 col-lg-4 mb-4" key={post.node.id}>
                  <div className="blog-item bg-white h-100">
                    <Link to={post.node.slug.current}>
                      <div
                        className="blog-image"
                        style={{
                          backgroundImage: `url(${
                            post.node.mainImage !== null
                              ? post.node.mainImage.asset.fluid.src
                              : "https://source.unsplash.com/user/joshhild/500x500"
                          })`,
                        }}
                      />
                    </Link>
                    <div className="blog-text">
                      <Link to={post.node.slug.current}>
                        <h4>{post.node.title}</h4>
                      </Link>
                      <p className="pt-2 text-muted">{post.node.excerpt}</p>
                      <span className="text-muted small">
                        <i className="fa fa-calendar-o pr-1" />
                        {post.node.publishedAt}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <Link className="btn btn-primary" role="button" to="/blog">
                More from the Blog
              </Link>
            </div>
          </div>
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanitySuccessStories(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        excerpt
        slug {
          current
        }
      }
    }
  }
`
