import React from "react"
import { Link } from "gatsby"
import HeroSwiper from "../swiper/hero-swiper"
import Follow from "../modal/follow-up"

import logohero from "../../images/home/Logo-Big-Hero.svg"

const Hero = () => (
  <div id="hero">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="hero-content">
            <HeroSwiper>
              <div className="swiper-slide">
                <img src={logohero} className="img-fluid logo-hero" />
                <div className="hero-body text-center">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="hero-body-content">
                        <h2 className="hero-title text-white mb-4">
                          Premium Finance Companies
                        </h2>
                      </div>
                      <h5 className="hero-subtitle text-white">
                        Agency or MGA affiliated Premium
                        <br />
                        Finance Co.
                      </h5>
                    </div>
                    <div className="col-lg-6">
                      <div className="hero-body-content">
                        <h5 className="hero-subtitle text-white">
                          National Partners provides funding lines! Higher
                          leverage and easier to use thank bank lines.
                        </h5>
                        <br />
                        <h5 className="hero-subtitle text-white">
                          Knowledgeable lender to help grow your book.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <img src={logohero} className="img-fluid logo-hero" />
                <div className="hero-body text-center">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="hero-body-content">
                        <h2 className="hero-title text-white mb-4">
                          Agent Focused
                        </h2>
                        <h5 className="hero-subtitle text-white">
                          Helping you meet your insured’s needs to help you sell
                          more insurance.
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="hero-body-content">
                        <ul className="p-0">
                          <li>
                            <h5 className="hero-subtitle text-white">
                              Ease of use system Integration
                            </h5>
                          </li>
                          <li>
                            <h5 className="hero-subtitle text-white">
                              Innovative agent revenue structures
                            </h5>
                          </li>
                          <li>
                            <h5 className="hero-subtitle text-white">
                              System controls and transparent reporting
                            </h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </HeroSwiper>
            <div className="hero-cta text-center mt-5">
              <Follow />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Hero
