import React from "react"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"

class SwiperSample extends React.Component {
  render() {
    const { children, slidesperview } = this.props
    const params = {
      direction: "vertical",
      effect: "flip",
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 2000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      // breakpoints: {
      //   768: {
      //     slidesPerView: 3,
      //     spaceBetween: 30
      //   },
      //   640: {
      //     slidesPerView: 2,
      //     spaceBetween: 20
      //   },
      //   320: {
      //     slidesPerView: 1,
      //     spaceBetween: 10
      //   }
      // }
    }
    return <Swiper {...params}>{children}</Swiper>
  }
}

export default SwiperSample
